import styled from 'styled-components';
import isEqual from 'lodash/isEqual';
import { useTranslation } from 'react-i18next';
import { ChangeEvent, useCallback, useMemo, memo } from 'react';
import Select from '../../../common/components/form/select/select';
import { MultiRangeSliderInput } from '../../../forms/components/common/multi-range-slider-input';
import {
  BUILDING_YEAR_MAX,
  BUILDING_YEAR_MIN,
  DEFAULT_PROPERTY_FILTERS_STATE,
  LIVING_AREA_MAX,
  LIVING_AREA_MIN,
  NUMBER_OF_ROOMS_MAX,
  NUMBER_OF_ROOMS_MIN,
  PROPERTY_PERIODS,
  PROPERTY_TYPES,
} from '../../constants';
import { InputBox } from '../../../common/components/form/input/input-box';
import { DarkBlueGhostButton } from '../../../common/components/ui/buttons/dark-blue-ghost-button';
import { device } from '../../../../style/theme';
import { hexToRGB } from '../../../common/utils/hex-to-rgb';
import { useDidUpdateEffect } from '../../../common/hooks/useDidUpdateEffect';
import {
  applyDeepFilters,
  clearPropertiesCache,
  mergeFilters,
  setFilters,
} from '../../redux/similarPropertiesSlice';
import {
  getFilterRangeArray,
  getFilterRangeObject,
  getSalePriceFilter,
} from '../../utils/filters';
import { getPropertyPeriod } from '../../utils/date';
import { removeAllMarkersExceptHome } from '../../../map/redux/mapSlice';
import { useAppDispatch } from '../../../common/hooks';
import { Property } from '../../../../generated';
import { IRangeObject, ISimilarPropertiesFilters } from '../../interfaces';

const Row = styled.div`
  display: flex;
  align-items: center;
  margin: 0 -7px;
  min-width: 100%;
  &:not(:last-of-type) {
    margin-bottom: 12px;
  }
  &:last-of-type {
    margin-bottom: 16px;
  }
  @media ${device.tablet} {
    flex-flow: row wrap;
  }
`;

const LabelWrapper = styled.div`
  flex: 0 0 45%;
  max-width: 45%;
  padding: 0 7px;
  @media ${device.tablet} {
    flex: 0 0 100%;
    max-width: none;
    margin: 0 0 8px;
  }
`;

const Label = styled.div`
  font-family: Roboto;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.5px;
  color: ${({ theme }) => hexToRGB(theme.blue, 0.5)};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const FormItemWrapper = styled.div`
  flex: 0 0 55%;
  max-width: 55%;
  padding: 0 7px;
  @media ${device.tablet} {
    flex: 0 0 100%;
    max-width: none;
  }
`;

const InputBoxRow = styled.div`
  display: flex;
  align-items: center;
  min-width: 100%;
  margin: 0 -6px;
`;

const InputBoxWrapper = styled.div`
  margin: 0 6px;
  flex: 1;
`;

const ButtonWrapper = styled.div`
  margin-top: 16px;
`;

let timeoutId: NodeJS.Timeout | undefined;

interface IProps {
  property: Property;
  filterState: ISimilarPropertiesFilters;
  numberOfRooms: number[];
  setFilterState: React.Dispatch<
    React.SetStateAction<ISimilarPropertiesFilters>
  >;
  setNumberOfRooms: React.Dispatch<React.SetStateAction<number[]>>;
}

const FiltersFormBase = ({
  property,
  filterState,
  numberOfRooms,
  setFilterState,
  setNumberOfRooms,
}: IProps): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const getNewStateOnChange = <T,>(
    type: 'min' | 'max',
    state: T,
    number: number
  ) => {
    return {
      ...state,
      ...(type === 'min' ? { min: number } : { max: number }),
    };
  };

  const onSelectChange = useCallback(
    (name: keyof ISimilarPropertiesFilters) => (value: unknown) => {
      setFilterState((prevState) => {
        return {
          ...prevState,
          [name]: value,
        };
      });
    },
    [setFilterState]
  );

  const onInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { name, value } = event.target;
      const stateName = name.split('-')[0] as keyof ISimilarPropertiesFilters;
      const type = name.split('-')[1];

      setFilterState((prevState) => {
        return {
          ...prevState,
          [stateName]: getNewStateOnChange(
            type as keyof IRangeObject,
            prevState[stateName],
            Number(value || 0)
          ),
        };
      });
    },
    [setFilterState]
  );

  const propertyTypeValue = useMemo(
    () =>
      PROPERTY_TYPES.find((type) => type.value === filterState.propertyType)
        ?.label as string,
    [filterState]
  );

  const propertyPeriodValue = useMemo(
    () =>
      PROPERTY_PERIODS.find((type) => type.value === filterState.propertyPeriod)
        ?.label as string,
    [filterState.propertyPeriod]
  );

  const onNumberOfRoomsChange = useCallback(
    (value: number[]) => {
      setNumberOfRooms(value);
      setFilterState((prevState) => ({
        ...prevState,
        numberOfRooms: value,
      }));
    },
    [setFilterState]
  );

  // const onFilterClear = useCallback(() => {
  //   setNumberOfRooms(DEFAULT_PROPERTY_FILTERS_STATE.numberOfRooms);
  //   setFilterState(DEFAULT_PROPERTY_FILTERS_STATE);
  // }, [setFilterState]);

  const onSaveFilters = () => {
    dispatch(
      applyDeepFilters({
        isNew:
          typeof filterState.propertyType === 'boolean'
            ? filterState.propertyType
            : undefined,
        numberOfRooms: getFilterRangeArray(
          NUMBER_OF_ROOMS_MIN,
          NUMBER_OF_ROOMS_MAX,
          filterState.numberOfRooms
        ),
        startDate: {
          min: getPropertyPeriod(filterState.propertyPeriod),
        },
        salePrice: getSalePriceFilter(filterState.salePrice),
        livingArea: getFilterRangeObject(
          LIVING_AREA_MIN,
          LIVING_AREA_MAX,
          filterState.livingArea
        ),
        buildingYear: getFilterRangeObject(
          BUILDING_YEAR_MIN,
          BUILDING_YEAR_MAX,
          filterState.buildingYear
        ),
      })
    );
    dispatch(removeAllMarkersExceptHome());
    dispatch(clearPropertiesCache());
    dispatch(
      mergeFilters({
        offset: 0,
        limit: 6,
      })
    );
  };

  // useDidUpdateEffect(() => {
  //   if (timeoutId) {
  //     clearTimeout(timeoutId);
  //   }
  //   return;
  //   timeoutId = setTimeout(() => {
  //     dispatch(
  //       applyDeepFilters({
  //         isNew:
  //           typeof filterState.propertyType === 'boolean'
  //             ? filterState.propertyType
  //             : undefined,
  //         numberOfRooms: getFilterRangeArray(
  //           NUMBER_OF_ROOMS_MIN,
  //           NUMBER_OF_ROOMS_MAX,
  //           filterState.numberOfRooms
  //         ),
  //         startDate: {
  //           min: getPropertyPeriod(filterState.propertyPeriod),
  //         },
  //         salePrice: getSalePriceFilter(filterState.salePrice),
  //         livingArea: getFilterRangeObject(
  //           LIVING_AREA_MIN,
  //           LIVING_AREA_MAX,
  //           filterState.livingArea
  //         ),
  //         buildingYear: getFilterRangeObject(
  //           BUILDING_YEAR_MIN,
  //           BUILDING_YEAR_MAX,
  //           filterState.buildingYear
  //         ),
  //       })
  //     );
  //     dispatch(removeAllMarkersExceptHome());
  //     dispatch(clearPropertiesCache());
  //     dispatch(
  //       mergeFilters({
  //         offset: 0,
  //         limit: 6,
  //       })
  //     );
  //   }, 700);
  // }, [
  //   filterState.propertyType,
  //   filterState.salePrice,
  //   filterState.livingArea,
  //   filterState.buildingYear,
  //   filterState.propertyPeriod,
  //   filterState.numberOfRooms,
  //   setFilters,
  // ]);

  const removeLeadingZero = (number: number | undefined | null) => {
    return number && !Number.isNaN(number) ? number.toString() : '';
  };

  return (
    <>
      <Row>
        <LabelWrapper>
          <Label>{t('Ort')}</Label>
        </LabelWrapper>

        <FormItemWrapper>
          <Select
            isDisabled
            selectedOption={`(${property.propertyData.location.address.postCode}) ${property.propertyData.location.address.city}`}
            options={[]}
          />
        </FormItemWrapper>
      </Row>

      <Row>
        <LabelWrapper>
          <Label>{t('Anzahl Zimmer')}</Label>
        </LabelWrapper>

        <FormItemWrapper>
          <MultiRangeSliderInput
            values={numberOfRooms}
            min={NUMBER_OF_ROOMS_MIN}
            max={NUMBER_OF_ROOMS_MAX}
            onChange={onNumberOfRoomsChange}
          />
        </FormItemWrapper>
      </Row>

      <Row>
        <LabelWrapper>
          <Label>{t('Nettowohnfläche')} (m²)</Label>
        </LabelWrapper>

        <FormItemWrapper>
          <InputBoxRow>
            <InputBoxWrapper>
              <InputBox
                name="livingArea-min"
                type="number"
                label="Von"
                placeholder="Von"
                value={removeLeadingZero(filterState.livingArea.min)}
                onChange={onInputChange}
              />
            </InputBoxWrapper>

            <InputBoxWrapper>
              <InputBox
                name="livingArea-max"
                type="number"
                label="Bis"
                placeholder="Bis"
                value={removeLeadingZero(filterState.livingArea.max)}
                onChange={onInputChange}
              />
            </InputBoxWrapper>
          </InputBoxRow>
        </FormItemWrapper>
      </Row>

      <Row>
        <LabelWrapper>
          <Label>{t('Preis')}</Label>
        </LabelWrapper>

        <FormItemWrapper>
          <InputBoxRow>
            <InputBoxWrapper>
              <InputBox
                name="salePrice-min"
                type="number"
                label="Von"
                placeholder="Von"
                value={removeLeadingZero(filterState.salePrice.min)}
                onChange={onInputChange}
              />
            </InputBoxWrapper>

            <InputBoxWrapper>
              <InputBox
                name="salePrice-max"
                type="number"
                label="Bis"
                placeholder="Bis"
                value={removeLeadingZero(filterState.salePrice.max)}
                onChange={onInputChange}
              />
            </InputBoxWrapper>
          </InputBoxRow>
        </FormItemWrapper>
      </Row>

      <Row>
        <LabelWrapper>
          <Label>{t('Zeitraum')}</Label>
        </LabelWrapper>

        <FormItemWrapper>
          <Select
            selectedOption={propertyPeriodValue}
            options={PROPERTY_PERIODS}
            onSelect={onSelectChange('propertyPeriod')}
          />
        </FormItemWrapper>
      </Row>

      <Row>
        <LabelWrapper>
          <Label>{t('Angebotsstatus')}</Label>
        </LabelWrapper>

        <FormItemWrapper>
          <Select
            isDisabled
            selectedOption="similar-properties.filter.status.all"
            options={[]}
          />
        </FormItemWrapper>
      </Row>

      <Row>
        <LabelWrapper>
          <Label>{t('Baujahr')}</Label>
        </LabelWrapper>

        <FormItemWrapper>
          <InputBoxRow>
            <InputBoxWrapper>
              <InputBox
                name="buildingYear-min"
                type="number"
                label="Von"
                value={removeLeadingZero(filterState.buildingYear.min)}
                placeholder="Von"
                onChange={onInputChange}
              />
            </InputBoxWrapper>

            <InputBoxWrapper>
              <InputBox
                name="buildingYear-max"
                type="number"
                label="Bis"
                value={removeLeadingZero(filterState.buildingYear.max)}
                placeholder="Bis"
                onChange={onInputChange}
              />
            </InputBoxWrapper>
          </InputBoxRow>
        </FormItemWrapper>
      </Row>

      <Row>
        <LabelWrapper>
          <Label>{t('Immobilientypen')}</Label>
        </LabelWrapper>

        <FormItemWrapper>
          <Select
            selectedOption={propertyTypeValue}
            options={PROPERTY_TYPES}
            onSelect={onSelectChange('propertyType')}
          />
        </FormItemWrapper>
      </Row>
      {/* {!isEqual(DEFAULT_PROPERTY_FILTERS_STATE, filterState) && ( */}
      {/*  <ButtonWrapper> */}
      {/*    <DarkBlueGhostButton */}
      {/*      fluid */}
      {/*      label="Filter zurücksetzen" */}
      {/*      onClick={onFilterClear} */}
      {/*    /> */}
      {/*  </ButtonWrapper> */}
      {/* )} */}
      <ButtonWrapper>
        <DarkBlueGhostButton
          fluid
          label={t('Speichern')}
          onClick={onSaveFilters}
        />
      </ButtonWrapper>
    </>
  );
};

const FiltersForm = memo(FiltersFormBase);

export { FiltersForm };
